var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dashboard-layout',[_c('div',{staticClass:"flex-col w-11/12 mx-auto mt-4"},[_c('div',{staticClass:"flex border-2 border-green-1002 flex px-6 py-3 rounded-lg w-min mb-4"},[_c('router-link',{attrs:{"to":{ name: 'edit-participant', params: { id: _vm.participant } }}},[_vm._v(" Voltar ")])],1),_c('div',{staticClass:"flex-col w-full mb-4 border-2 rounded-lg border-green-1002 p-3 mb-8"},[_c('div',{staticClass:"flex justify-between items-center"},[_c('h2',{staticClass:"text-2xl font-bold"},[_vm._v("Formações Acadêmicas")]),_c('router-link',{staticClass:"border-2 border-green-1002 py-1 px-4 rounded-md bg-white mr-4",attrs:{"to":{
            name: 'create-participant-academic-background',
            params: { participant: _vm.participant }
          }}},[_c('i',{staticClass:"text-2xl text-green-1002 ri-add-fill"})])],1),_vm._l((_vm.academicBackgrounds),function(academicBackground){return _c('div',{key:academicBackground.id,staticClass:"py-4 px-4"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex flex-col flex-grow"},[_c('h3',{staticClass:"text-xl font-bold"},[_vm._v(" "+_vm._s(academicBackground.institution)+" ")]),_c('p',[_vm._v(_vm._s(academicBackground.formation))])]),_c('div',{staticClass:"flex"},[_c('router-link',{staticClass:"border-2 border-green-1002 py-1 px-4 rounded-md bg-white mr-4",attrs:{"to":{
                name: 'edit-participant-academic-background',
                params: {
                  participant: _vm.participant,
                  id: academicBackground.id
                }
              }}},[_c('i',{staticClass:"text-2xl text-green-1002 ri-pencil-fill"})]),_c('button',{staticClass:"border-2 border-green-1002 py-1 px-4 rounded-md bg-white",on:{"click":function($event){$event.preventDefault();return _vm.deleteAcademicBackground(academicBackground.id)}}},[_c('i',{staticClass:"text-2xl text-green-1002 ri-delete-bin-fill"})])],1)])])})],2),_c('div',{staticClass:"flex-col w-full mb-4 border-2 rounded-lg border-green-1002 p-3 mb-8"},[_c('div',{staticClass:"flex justify-between items-center"},[_c('h2',{staticClass:"text-2xl font-bold"},[_vm._v("Experiências")]),_c('router-link',{staticClass:"border-2 border-green-1002 py-1 px-4 rounded-md bg-white mr-4",attrs:{"to":{
            name: 'create-participant-experience',
            params: { participant: _vm.participant }
          }}},[_c('i',{staticClass:"text-2xl text-green-1002 ri-add-fill"})])],1),_vm._l((_vm.experiences),function(experience){return _c('div',{key:experience.id,staticClass:"py-4 px-4"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex flex-col flex-grow"},[_c('h3',{staticClass:"text-xl font-bold"},[_vm._v(_vm._s(experience.company))]),_c('p',[_vm._v(_vm._s(experience.title))])]),_c('div',{staticClass:"flex"},[_c('router-link',{staticClass:"border-2 border-green-1002 py-1 px-4 rounded-md bg-white mr-4",attrs:{"to":{
                name: 'edit-participant-experience',
                params: { participant: _vm.participant, id: experience.id }
              }}},[_c('i',{staticClass:"text-2xl text-green-1002 ri-pencil-fill"})]),_c('button',{staticClass:"border-2 border-green-1002 py-1 px-4 rounded-md bg-white",on:{"click":function($event){$event.preventDefault();return _vm.deleteExperience(experience.id)}}},[_c('i',{staticClass:"text-2xl text-green-1002 ri-delete-bin-fill"})])],1)])])})],2),_c('div',{staticClass:"flex-col w-full mb-4 border-2 rounded-lg border-green-1002 p-3 mb-8"},[_c('div',{staticClass:"flex justify-between items-center"},[_c('h2',{staticClass:"text-2xl font-bold"},[_vm._v("Cursos Complementares")]),_c('router-link',{staticClass:"border-2 border-green-1002 py-1 px-4 rounded-md bg-white mr-4",attrs:{"to":{
            name: 'create-participant-complementary-course',
            params: { participant: _vm.participant }
          }}},[_c('i',{staticClass:"text-2xl text-green-1002 ri-add-fill"})])],1),_vm._l((_vm.complementaryCourses),function(complementaryCourse){return _c('div',{key:complementaryCourse.id,staticClass:"py-4 px-4"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex flex-col flex-grow"},[_c('h3',{staticClass:"text-xl font-bold"},[_vm._v(" "+_vm._s(complementaryCourse.issuer)+" ")]),_c('p',[_vm._v(" "+_vm._s(complementaryCourse.name)+", "+_vm._s(complementaryCourse.workload)+" horas ")])]),_c('div',{staticClass:"flex"},[_c('router-link',{staticClass:"border-2 border-green-1002 py-1 px-4 rounded-md bg-white mr-4",attrs:{"to":{
                name: 'edit-participant-complementary-course',
                params: {
                  participant: _vm.participant,
                  id: complementaryCourse.id
                }
              }}},[_c('i',{staticClass:"text-2xl text-green-1002 ri-pencil-fill"})]),_c('button',{staticClass:"border-2 border-green-1002 py-1 px-4 rounded-md bg-white",on:{"click":function($event){$event.preventDefault();return _vm.deleteComplementaryCourse(complementaryCourse.id)}}},[_c('i',{staticClass:"text-2xl text-green-1002 ri-delete-bin-fill"})])],1)])])})],2),_c('div',{staticClass:"flex-col w-full border-2 rounded-lg border-green-1002 p-3 mb-8"},[_c('div',{staticClass:"flex justify-between items-center mb-3"},[_c('h2',{staticClass:"text-2xl font-bold"},[_vm._v("Áreas de Interesse")]),_c('router-link',{staticClass:"border-2 border-green-1002 py-1 px-4 rounded-md bg-white mr-4",attrs:{"to":{
            name: 'edit-participant-interest-areas',
            params: { participant: _vm.participant }
          }}},[_c('i',{staticClass:"text-2xl text-green-1002 ri-pencil-fill"})])],1),_c('div',[_vm._v(" "+_vm._s(_vm.interestAreas)+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }