<template>
  <dashboard-layout>
    <div class="flex-col w-11/12 mx-auto mt-4">
      <div
        class="flex border-2 border-green-1002 flex px-6 py-3 rounded-lg w-min mb-4"
      >
        <router-link
          :to="{ name: 'edit-participant', params: { id: participant } }"
        >
          Voltar
        </router-link>
      </div>
      <div
        class="flex-col w-full mb-4 border-2 rounded-lg border-green-1002 p-3 mb-8"
      >
        <div class="flex justify-between items-center">
          <h2 class="text-2xl font-bold">Formações Acadêmicas</h2>
          <router-link
            :to="{
              name: 'create-participant-academic-background',
              params: { participant: participant }
            }"
            class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white mr-4"
          >
            <i class="text-2xl text-green-1002 ri-add-fill"></i>
          </router-link>
        </div>
        <div
          v-for="academicBackground in academicBackgrounds"
          class="py-4 px-4"
          :key="academicBackground.id"
        >
          <div class="flex items-center">
            <div class="flex flex-col flex-grow">
              <h3 class="text-xl font-bold">
                {{ academicBackground.institution }}
              </h3>
              <p>{{ academicBackground.formation }}</p>
            </div>
            <div class="flex">
              <router-link
                :to="{
                  name: 'edit-participant-academic-background',
                  params: {
                    participant: participant,
                    id: academicBackground.id
                  }
                }"
                class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white mr-4"
              >
                <i class="text-2xl text-green-1002 ri-pencil-fill"></i>
              </router-link>
              <button
                @click.prevent="deleteAcademicBackground(academicBackground.id)"
                class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white"
              >
                <i class="text-2xl text-green-1002 ri-delete-bin-fill"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex-col w-full mb-4 border-2 rounded-lg border-green-1002 p-3 mb-8"
      >
        <div class="flex justify-between items-center">
          <h2 class="text-2xl font-bold">Experiências</h2>
          <router-link
            :to="{
              name: 'create-participant-experience',
              params: { participant: participant }
            }"
            class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white mr-4"
          >
            <i class="text-2xl text-green-1002 ri-add-fill"></i>
          </router-link>
        </div>
        <div
          v-for="experience in experiences"
          class="py-4 px-4"
          :key="experience.id"
        >
          <div class="flex items-center">
            <div class="flex flex-col flex-grow">
              <h3 class="text-xl font-bold">{{ experience.company }}</h3>
              <p>{{ experience.title }}</p>
            </div>
            <div class="flex">
              <router-link
                :to="{
                  name: 'edit-participant-experience',
                  params: { participant: participant, id: experience.id }
                }"
                class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white mr-4"
              >
                <i class="text-2xl text-green-1002 ri-pencil-fill"></i>
              </router-link>
              <button
                @click.prevent="deleteExperience(experience.id)"
                class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white"
              >
                <i class="text-2xl text-green-1002 ri-delete-bin-fill"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex-col w-full mb-4 border-2 rounded-lg border-green-1002 p-3 mb-8"
      >
        <div class="flex justify-between items-center">
          <h2 class="text-2xl font-bold">Cursos Complementares</h2>
          <router-link
            :to="{
              name: 'create-participant-complementary-course',
              params: { participant: participant }
            }"
            class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white mr-4"
          >
            <i class="text-2xl text-green-1002 ri-add-fill"></i>
          </router-link>
        </div>
        <div
          v-for="complementaryCourse in complementaryCourses"
          class="py-4 px-4"
          :key="complementaryCourse.id"
        >
          <div class="flex items-center">
            <div class="flex flex-col flex-grow">
              <h3 class="text-xl font-bold">
                {{ complementaryCourse.issuer }}
              </h3>
              <p>
                {{ complementaryCourse.name }},
                {{ complementaryCourse.workload }} horas
              </p>
            </div>
            <div class="flex">
              <router-link
                :to="{
                  name: 'edit-participant-complementary-course',
                  params: {
                    participant: participant,
                    id: complementaryCourse.id
                  }
                }"
                class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white mr-4"
              >
                <i class="text-2xl text-green-1002 ri-pencil-fill"></i>
              </router-link>
              <button
                @click.prevent="
                  deleteComplementaryCourse(complementaryCourse.id)
                "
                class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white"
              >
                <i class="text-2xl text-green-1002 ri-delete-bin-fill"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex-col w-full border-2 rounded-lg border-green-1002 p-3 mb-8"
      >
        <div class="flex justify-between items-center mb-3">
          <h2 class="text-2xl font-bold">Áreas de Interesse</h2>
          <router-link
            class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white mr-4"
            :to="{
              name: 'edit-participant-interest-areas',
              params: { participant: participant }
            }"
          >
            <i class="text-2xl text-green-1002 ri-pencil-fill"></i>
          </router-link>
        </div>
        <div>
          {{ interestAreas }}
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../../layouts/DashboardLayout';
import axios from '@/utils/axios';

export default {
  name: 'CurriculumIndex',

  title() {
    return `${process.env.VUE_APP_NAME} | Currículo Complementar do Talento`;
  },

  components: {
    DashboardLayout
  },

  props: {
    participant: {
      required: true,
      type: Number
    }
  },

  data() {
    return {
      academicBackgrounds: [],
      experiences: [],
      complementaryCourses: [],
      interestAreas: ''
    };
  },

  methods: {
    async fetchAcademicBackgrounds() {
      const response = await axios.get(
        `/api/formacoes-academicas/${this.participant}`
      );

      this.academicBackgrounds = response.data.data;
    },

    async fetchExperiences() {
      const response = await axios.get(`/api/experiencias/${this.participant}`);

      this.experiences = response.data.data;
    },

    async fetchComplementaryCourses() {
      const response = await axios.get(
        `/api/cursos-complementares/${this.participant}`
      );

      this.complementaryCourses = response.data.data;
    },

    async fetchInterestAreas() {
      const response = await axios.get(
        `/api/areas-de-interesse/${this.participant}`
      );

      this.interestAreas = response.data.data
        .map(interestArea => interestArea.label)
        .join(', ');
    },

    deleteAcademicBackground(id) {
      this.$confirm({
        message: 'Tem certeza que deseja excluir esta Formação Acadêmica?',
        button: {
          no: 'Não',
          yes: 'Sim'
        },
        callback: confirm => {
          if (confirm) {
            axios.delete(`/api/formacoes-academicas/${id}`).then(({ data }) => {
              this.$toast.success(data.data.message);

              this.academicBackgrounds = this.academicBackgrounds.filter(
                academicBackground => {
                  return academicBackground.id !== id;
                }
              );
            });
          }
        }
      });
    },

    deleteComplementaryCourse(id) {
      this.$confirm({
        message: 'Tem certeza que deseja excluir este Curso Complementar?',
        button: {
          no: 'Não',
          yes: 'Sim'
        },
        callback: confirm => {
          if (confirm) {
            axios
              .delete(`/api/cursos-complementares/${id}`)
              .then(({ data }) => {
                this.$toast.success(data.data.message);

                this.complementaryCourses = this.complementaryCourses.filter(
                  complementaryCourse => {
                    return complementaryCourse.id !== id;
                  }
                );
              });
          }
        }
      });
    },

    deleteExperience(id) {
      this.$confirm({
        message: 'Tem certeza que deseja excluir esta Experiência?',
        button: {
          no: 'Não',
          yes: 'Sim'
        },
        callback: confirm => {
          if (confirm) {
            axios.delete(`/api/experiencias/${id}`).then(({ data }) => {
              this.$toast.success(data.data.message);

              this.experiences = this.experiences.filter(experience => {
                return experience.id !== id;
              });
            });
          }
        }
      });
    }
  },

  created() {
    this.fetchAcademicBackgrounds();
    this.fetchExperiences();
    this.fetchComplementaryCourses();
    this.fetchInterestAreas();
  }
};
</script>

<style scoped></style>
